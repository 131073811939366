import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import authMiddleware from '@/middlewares/auth.middleware';

const routes = [
    {
        path: "/",
        name: "login",
        component: () => import("@/views/auth/Login.vue"),
    },
    {
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "/home",
                name: "dashboard",
                component: () => import("@/views/dashboard/Dashboard.vue"),
                meta: { requiresAuth: true, roles: ['validator', 'other'] },
            },
            {
                path: "/rawat-jalan",
                name: "rawat-jalan",
                component: () => import("@/views/rawat-jalan/RawatJalan.vue"),
                meta: { requiresAuth: true, roles: ['other'] },
            },
            {
                path: "/rawat-inap",
                name: "rawat-inap",
                component: () => import("@/views/rawat-inap/RawatInap.vue"),
                meta: { requiresAuth: true, roles: ['other'] },
            },
            {
                path: "/keuangan",
                name: "keuangan",
                component: () => import("@/views/keuangan/Keuangan.vue"),
                meta: { requiresAuth: true, roles: ['validator', 'other'] },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(authMiddleware);

export default router;
