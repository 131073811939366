import { getFromLocal } from "@/helper/local.helper";

export default function authMiddleware(to, from, next) {
    const isAuthenticated = getFromLocal();

    if (to.path === "/" && isAuthenticated) {
        next("/home");
    }

    const allowedRoles = to.meta.roles;
    if (!allowedRoles || allowedRoles.includes(isAuthenticated?.role)) {
        return next();
    }

    return next("/");
}
